@import './variables.scss';
body{
    margin: 0;
    height: 100vh;
}
.bmw-ca-theme{
    height: 100vh;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    & header{
      border-bottom: 1px solid var(--menu-border-color);
      & .nav-secondary{
        height: 40px;
        background-color: rgb(var(--shadow-color));
        padding-right: 65px;
        @media(max-width: $xmedium){
          padding-right: 0;
        }
        & .btn-account-dropdown .mat-mdc-button{
          padding-right: 0;
          & span{
            color: var(--white) !important;
            text-transform: none;
            font-family: $helvetica;
            font-size: 11px;
            font-weight: 700;
            display: flex;
            align-items: flex-end;
            &.mat-mdc-ripple.mat-button-ripple{
              content: url('../src/assets/images/icon-chevron-up.svg');
              width: 10px;
              height: 16px;
              margin-left: 5px;
              position: relative;
              transform: rotate(180deg);
            }
          }
          &.menu-open{
            & span{
              &.mat-mdc-ripple.mat-button-ripple{
                transform: rotate(0deg);
              }
            }
          }
          & .fs-icon{
            &.menuIcon{
              content: url('../src/assets/images/icon-my-bmw.svg');
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
          
        }
        & .fs-icon.minilogo{
          content: ''
        }
      }
    }
    & .desktop-header-container{
      height: 60px;
      background-color: var(--white);
      max-width: 1200px;
      width: 100%;
      margin: auto;
      position: relative;
      padding: 0 20px;
      @media(max-width: $xmedium){
        padding: 0;
      }
      & .sub-nav-header{
        display: inline-flex;
        height: 60px;
      }
      & .brand-image{
        position: absolute;
        top: -25px;
        left: 20px;
        width: 90px;
        z-index: 100;
        @media(max-width: $xmedium){
          display: none;
        }
      }
      & .brand-logo{
        cursor: pointer;
        @media(max-width: $xmedium){
          background: url('../src/assets/images/logo-bmw-circle.png') no-repeat 50%;
          background-size: 60%;
          width: 60px;
          height: 100%;
          position: static;
          border-right: 1px solid var(--menu-border-color);
          padding-right: 50px;
        }
      }
      & .page-title{
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }
}
.bmw-footer{
    border-top: 2px solid var(--light-gray);
    height: auto;
    padding: 0;
    & footer{
        padding: 0;
        border: 0px ;
        @media(max-width: $caxlarge){
          padding: 10px 0;
        }
    }
    & nav{
        max-width: 1200px;
        padding: 0 20px;
    }
    & .container{
        text-align: left;
        width: 100%;
        & .copyright{
            font-size: 11px;
            font-weight: 700;
            color: rgb(var(--shadow-color));
            margin-bottom: 20px;
            margin-top: 0;
            font-family: $helvetica;
            &.desktop{
              @media(max-width: $caxlarge){
                text-align: center;
                margin: 15px 0 ;
              }
              @media(max-width: $large){
                  display: block;
              }
            }
            &.mobile{
                @media(max-width: $large){
                    display: none;
                }
            }
        }
        & ul{
            text-align: left;
            margin-left: 40px;
            @media(max-width: $caxlarge){
              margin-left: 0;
              text-align: center;
            }
            & li{
                padding-right: 0;
                &:last-child{
                  & a{
                    &:after{
                      display: none;
                    }
                  }
                }
                &.active{
                    & a{
                        font-weight: normal !important;
                        color: rgb(var(--shadow-color));
                    }
                }
                & a{
                    color: rgba(var(--shadow-color), var(--opacity065));
                    font-size: 11px;
                    font-weight: 400;
                    font-family: $helvetica;
                    cursor: pointer;
                    &:after{
                        content: "|";
                        margin: 0 20px;
                        @media(max-width: $caxlarge){
                          margin: 0 10px;
                        }
                    }
                }
                &:after{
                    display: none;
                }
            }
        }
    }
    
}
.terms{
    &-wrapper{
        background: url('../../_shared/shared/assets/images/bmw-background-image.jpg') no-repeat scroll 100% 0;
        background-size: cover;
        padding: 50px 0;
    }
    &-buttons{
        margin-top: 20px;
    }
    &-action-buttons{
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid var(--input-border-gray);
        padding: 20px 0;
        & .fs-button-btn{
            border-radius: 0;
            font-size: 11px;
            line-height: 11px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 15px 30px !important;
            border: 1px solid var(--primary-blue) !important;
            opacity: 1;
            cursor: pointer;
            color: var(--white) !important;
            background: var(--primary-blue) !important;
            font-family: $helvetica;
            &:hover{
                background: none !important;
                color: var(--primary-blue) !important;
            }
            &-disabled{
                cursor: not-allowed;
                opacity: .5;
            } 
            &.fs-button-btn-secondary{
                background: var(--white) !important;
                border: 1px solid var(--border-gray-dark) !important;
                color: var(--dark) !important;
                margin: 0 0 0 10px !important;
                &:hover{
                    border: 1px solid rgb(var(--shadow-color)) !important;
                    color: var(--dark) !important;

                }
            }           
        }
    }
    &-checkbox{
        .mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background{
            background: var(--primary-blue);
        }
        & .mat-mdc-checkbox-label{
            font-size: 12px;
            line-height: 14px;
            color: var(--dark);
            font-family: $helvetica;
        }
        & .fs-text.error{
            font-style: normal;
            font-size: 10px;
            color: var(--error-red);
            margin-top: 5px;
            line-height: 12px;
            font-family: $helvetica;
        }
        & .fs-anchor{
            font-family: $helvetica;
        }
    } 
    &-accept{
        &-wrapper{
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
        }
    }
}
.page-terms-and-conditions{
    padding: 0 !important;
    & .terms-and-conditions-container{
        padding-top: 20px !important;
    }
}
#termsContent {
    & h1{
        display: none;
    }
}
.print-page-html-content{
    #termsContent {
        & h1{
            display: block;
        }
    }
}
.fs-print-page .print-page-header-button svg{
    fill: var(--primary-blue)
}
.dialog-container {
    padding: 40px 30px 20px !important;
    max-width: 580px !important;
  
    .fs-text.title {
      margin-top: 0 !important;
      padding-top: 10px !important;
      text-transform: uppercase !important;
      font-size: 18px !important;
      line-height: 21px !important;
      font-weight: 700 !important;
      color: var(--dark) !important;
      margin-bottom: 20px !important;
      font-family: $helvetica !important;
  
      &:after {
        content: "";
        width: 40px;
        display: block;
        margin-top: 15px;
        border: 0.5px solid var(--dark);
      }
    }
  
  
    .dialog-bodytext {
      font-size: 16px !important;
      line-height: 24px !important;
      margin-bottom: 20px !important;
      font-family: $helvetica !important;
      color: var(--dark) !important;
      font-weight: 400 !important;
  
      @media(max-width: $xmedium) {
        margin-bottom: 20px !important;
        font-size: 12px !important;
        line-height: 18px !important;
      }
    }
  
    .dialog-buttonmultiple {
      justify-content: flex-end;
      padding-top: 20px;
      @media(max-width: $mobile) {
        flex-direction: column !important;
      }
  
      & .dialog-button {
        width: auto;
        padding-top: 20px;
  
        @media(max-width: $mobile) {
          width: 100%;
          padding-top: 10px;
        }
  
        &:first-child {
          margin: 0;
        }
  
        & .fs-button {
          &-btn {
            background-color: var(--primary-blue) !important;
            border: 1px solid var(--primary-blue) !important;
            color: var(--white) !important;
            font-family: $helvetica !important;
            border-radius: 0;
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 15px 30px;
            opacity: 1;
            cursor: pointer;
            margin: 20px 0 10px 10px;
            line-height: normal;
            height: auto !important;
            width: auto;
  
            &:hover {
              background-color: var(--white) !important;
              border: 1px solid var(--primary-blue) !important;
              color: var(--primary-blue) !important;
            }
  
            @media(max-width: $mobile) {
              width: 100%;
              margin: 0;
            }
  
            &-disabled {
              cursor: not-allowed;
              opacity: .5;
  
              &:hover {
                background-color: var(--primary-blue) !important;
                border: 1px solid var(--primary-blue) !important;
                color: var(--white) !important;
              }
            }
  
            &-secondary {
              background-color: var(--white) !important;
              border: 1px solid var(--border-gray-dark) !important;
              color: var(--dark) !important;
              padding: 15px 30px !important;
              margin: 20px 0 10px 10px !important;
              line-height: normal;
              height: auto !important;
              width: auto;
  
              &:hover {
                border: 1px solid var(--black) !important;
                color: var(--dark) !important;
              }
  
              @media(max-width: $mobile) {
                width: 100%;
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  
  
    &#sessionTimeOutDialog {
      padding: 40px 30px 15px !important;
      @media(max-width: $mobile) {
        max-width: 300px !important;
      }
      &.dialog-container{
        .fs-text.title{
          padding-top: 10px;
          text-transform: uppercase !important;
          font-size: 18px !important;
          line-height: 21px !important;
          font-weight: 700 !important;
          margin-bottom: 20px !important;
          @media(max-width: $tablet){
            margin-bottom: 20px !important;
          }
          @media(max-width: $mobile) {
            margin-bottom: 15px !important;
            letter-spacing: .35px;
          }
          &::after{
            content: "";
            width: 40px;
            display: block;
            position: static;
            background-color: var(--dark);
            margin-top: 15px;
          }
        }
        .dialog-bodytext{
          font-size: 16px !important;
          line-height: 24px !important;
          letter-spacing: .5px !important;
          margin-bottom: 20px !important;
          @media(max-width: $tablet){
            font-size: 15px !important;
            margin-bottom: 40px !important;
          }
          @media(max-width: $mobile){
            font-size: 12px !important;
            line-height: 1.5 !important;
            letter-spacing: normal !important;
          }
        }
      }
      .dialog-buttonmultiple {
      flex-direction: row;
        justify-content: flex-end;
        .dialog-button{
          padding-top: 0;
          &:first-child{
            @media(max-width: $mobile){
              padding-top: 10px;
            }
          }
        }
        @media (max-width: $mobile){
          flex-direction: column-reverse !important;
          padding: 0;
          margin-bottom: 10px;
        }
      }
    }
}
.cdk-overlay-container{
  background-color: rgba(var(--shadow-color), var(--opacity04));
  opacity: 1;
  & .cdk-overlay-pane{
    & .mat-mdc-menu-panel{
      min-width: 325px!important;
      max-width: 325px!important;
      right: -1px;
      border-radius: 4px;      
      & .mat-mdc-menu-content{
        background: var(--white);
        padding: 0;
        & .mat-mdc-menu-item{
          font-family: $helvetica;
          text-transform: none;
          color: rgb(var(--shadow-color));
          display: flex;
          align-items: center;
          transition: opacity .15s cubic-bezier(.505,.015,.46,.985);
          opacity: .5;
          background: var(--white);
          font-size: 12px;
          font-weight: 700;
          padding: 0 30px;
          height: 48px;
          border-bottom: 1px solid var(--header-dropdown-border);
          &:first-child{
            margin-top: 20px;
            border-bottom: 0;
          }
          &:last-child{
            color: rgb(var(--shadow-color));
            margin-top: 10px;
            margin-bottom: 10px;
            opacity: 1;
            border: 0;
          }
          &:hover{
            opacity: 1;
          }
          & fs-icon{
            display: flex;
            align-items: center;
          }
          & .fs-icon{
            width: 16px;
            margin-right: 10px;
          }
        }
      }
      & .fs-icon.logout{
        display: none;
      }
      & .fs-icon.edit{
        content: url('../src/assets/images/icon-edit-small.svg')
      }
    }
    
  }
}
.cdk-overlay-connected-position-bounding-box{
  &::after{
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--white);
    position: absolute;
    left: auto;
    right: 50px;
    margin: auto;
    top: -5px;
  }
  @media(max-width: $xmedium){
    right: 17px !important;
  }
}

@media (max-width: $xlarge) {
  .page-terms-and-conditions .terms-and-conditions-container {
      padding-left: 20px;
      padding-right: 20px;
  }
}

.page-terms-and-conditions .terms-and-conditions-container {
  max-width: 1100px;
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 900px;
}

#termsContent{
  h1 {
    font-size: var(--font-size-35);
    font-weight: var(--font-weight-300);
    text-transform: uppercase;
    color: var(--dark);
    margin-bottom: 10px;
  }

  h2 {
    font-size: var(--font-size-15);
    font-weight: var(--font-weight-bold);
  }

  h3 {
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-normal);
    text-transform: none;
  }

  h4 {
    font-size: var(--font-size-14);
    color: var(--dark);
    line-height: 24px;
    font-weight: var(--font-weight-400);
    margin-bottom: 20px;
  }

  .terms-header-section{
    padding-bottom: 40px;
    div{
      font-size: var(--font-size-14);
      padding-top: 5px;
    }
  }

  .terms-organization, .terms-section-para, .terms-contact, .terms-footer{
    font-size: var(--font-size-15);
    line-height: 18px;
    color: var(--dark);
    font-weight: 400;
  }

  .terms-section-para{

    padding-bottom: 10px;
  }

  .terms-section-header{
    padding-bottom: 10px;
    padding-top: 25px;
  }

  

  ol {
    margin-bottom: 0.2in;
  }

  ol li {
    font-size: var(--font-size-14);
    line-height: 21px;
    color: var(--dark);
    padding-left: 5px !important;
  }

  ol.lettered li {
    list-style-type: lower-alpha;
    
  }

  ol.romanletter li {
    list-style-type: lower-roman;
  }

  ol.lettered{
    padding-left: 25px;
  }

  ol.romanletter{
    padding-left: 25px;
  }

  .terms-footer{
    padding-bottom: 50px;
  }

  .terms-contact{
    padding-bottom: 15px;
  }
  
}